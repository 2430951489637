import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { fitLandingSection, distance, select, selectAll } from "./_utils";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const navbar = select(".navbar");
const sidebar = select(".sidebar");
const sliderWrapper = select("#sliderWrapper");
const sections = gsap.utils.toArray("section");
const chords = selectAll(".chord");
const chordLinks = selectAll("a.chord");

const cumulativeWidths = []; // Calculate the cumulative width of previous sections
let cumulativeWidth = 0;
sections.forEach((section) => {
  cumulativeWidth += section.scrollWidth;
  cumulativeWidths.push(cumulativeWidth);
});

[navbar, sidebar].forEach((el) => {
  const headerAnim = gsap
    .from(el, {
      yPercent: el === navbar ? -100 : undefined,
      xPercent: el === sidebar ? 300 : undefined,
      paused: true,
      duration: 0.4,
    })
    .progress(1);

  ScrollTrigger.create({
    start: "top top",
    end: 99999,
    scrub: 1,
    onUpdate: (self) => {
      self.direction === -1 ? headerAnim.play() : headerAnim.reverse();
    },
  });
});

const scrollTween = gsap.to(sliderWrapper, {
  x: window.innerWidth,
  xPercent: -100,
  ease: "none", // <-- IMPORTANT!
  scrollTrigger: {
    trigger: sliderWrapper,
    pin: true,
    scrub: 1,
    end: `+=${distance(sliderWrapper)}`,
    invalidateOnRefresh: true,
  },
});

chords.forEach((chord, index) => {
  gsap.to(chord, {
    x: () => -1 * window.innerWidth,
    xPercent: chords.length * chord.offsetWidth,
    ease: "none",
    scrollTrigger: {
      trigger: `#sec-${index}`,
      containerAnimation: scrollTween,
      start: `right right-=${(chords.length - index) * chord.offsetWidth}px`,
      end: `right left+=${index * chord.offsetWidth}px`,
      toggleActions: "play none none reset",
      scrub: true,
    },
  });
});

const parallaxes = selectAll(".parallax");
parallaxes.forEach((parallax) => {
  gsap.to(parallax, {
    scale: 0.9,
    scrollTrigger: {
      trigger: parallax,
      containerAnimation: scrollTween,
      start: "right right",
      end: "right left",
      toggleActions: "play reverse play reset", // onEnter onLeave onEnterBack onLeaveBack
      scrub: true,
    },
  });
});

chordLinks.forEach((link, index) => {
  link.addEventListener("click", (e) => {
    e.preventDefault();

    // Calculate the left position based on cumulative widths
    const leftPosition = index >= 0 ? cumulativeWidths[index] : 0;

    gsap.to(window, {
      duration: 1,
      scrollTo: {
        y: leftPosition,
      },
    });
  });
});

window.addEventListener("resize", () => {
  fitLandingSection();
  distance(sliderWrapper);
});

fitLandingSection();
