/**
 * A list of commonly used functions
 */

/**
 * A function to fit the landing section width based on window size and chords offset.
 *
 * @returns {void}
 */
function fitLandingSection() {
  const landingSection = document.querySelector("#sec-0");
  landingSection.style.width =
    window.innerWidth - document.querySelector(".chords").offsetWidth + "px";
}

/**
 * Calculates the distance between the end of the sliderWrapper and the right edge of the window.
 *
 * @param {HTMLElement} sliderWrapper
 * @return {number} The distance in pixels.
 */
function distance(sliderWrapper) {
  return sliderWrapper.scrollWidth - window.innerWidth;
}

/**
 * Returns the first element that is a descendant of the root element
 * that matches the specified selector, or group of selectors.
 *
 * @param {string} s The selector to query the DOM with.
 * @return {HTMLElement|null} The first matching element or null if no element found.
 */
const select = (s) => {
  return document.querySelector(s);
};

/**
 * Returns a NodeList of elements that are descendants of the root element
 * and match the specified selector, or group of selectors.
 *
 * @param {string} s The selector to query the DOM with.
 * @return {NodeList} A NodeList of matching elements.
 */
const selectAll = (s) => {
  return document.querySelectorAll(s);
};

export { distance, fitLandingSection, select, selectAll };
